import { ROLE } from 'src/app/shared/enums/role.enum';
import { SERVICE } from '@shared/enums/service.enum';
import { IUser } from '@shared/models/user.interface';
import { environment } from '../../../environments/environment';

export enum SectionRole {
  PartnerMenu,
  OperatorMenu,
  AdminMenu,
  ClientList,
  ESGTopicList,
  ESGSurveyList,
  ESGShowClientDataTable,
  TenantTab,
  ESGAddSurvey,
  ESGEditSurvey,
  McEditSurvey,
  ClientEdit,
  ClientAdd,
  ContractEdit,
  ContractAdd,
  Dashboard,
  McSurveyList,
  McTopicList,
  McAddSurvey,
  McAddYear,
  MaStructureList,
  MaTopic,
  MaFill,
  MaCreation,
  MaSurveyList,
  McCard,
  MaCard,
  EsgCard,
  NfrCard,
  ClientDelete,
  ContractDelete,
  McAddDashboardElement,
  SysAdminContractList,
  SysAdminImpersonate,
  EASupplierTopicList,
  EASupplierCard,
  EASupplierCardFill,
  EASupplierList,
  EASupplierStructureList,
  EASupplierAddStructure,
  EASupplierDetail,
  EASupplierSurvey
}

export const SectionRoleDict: Record<SectionRole, ROLE[]> = {
  [SectionRole.PartnerMenu]: [ROLE.PARTNER],
  [SectionRole.OperatorMenu]: [ROLE.OPERATOR],
  [SectionRole.AdminMenu]: [ROLE.ADMIN],

  [SectionRole.ClientList]: [ROLE.ADMIN, ROLE.PARTNER, ROLE.SYSTEM_ADMIN],
  [SectionRole.ClientEdit]: [ROLE.ADMIN, ROLE.PARTNER],
  [SectionRole.ClientAdd]: [ROLE.ADMIN, ROLE.PARTNER],
  [SectionRole.ContractEdit]: [ROLE.ADMIN, ROLE.PARTNER],
  [SectionRole.ContractAdd]: [ROLE.ADMIN, ROLE.PARTNER],
  [SectionRole.TenantTab]: [ROLE.ADMIN],
  [SectionRole.ClientDelete]: [ROLE.ADMIN],
  [SectionRole.ContractDelete]: [ROLE.ADMIN],
  [SectionRole.Dashboard]: [ROLE.OPERATOR],

  [SectionRole.SysAdminContractList]: [ROLE.SYSTEM_ADMIN],
  [SectionRole.SysAdminImpersonate]: [ROLE.SYSTEM_ADMIN],

  [SectionRole.EsgCard]: [ROLE.PARTNER, ROLE.OPERATOR],
  [SectionRole.ESGTopicList]: [ROLE.ADMIN],
  [SectionRole.ESGSurveyList]: [ROLE.OPERATOR, ROLE.ADMIN, ROLE.PARTNER],

  [SectionRole.ESGShowClientDataTable]: [ROLE.ADMIN, ROLE.PARTNER],
  [SectionRole.ESGAddSurvey]: [ROLE.OPERATOR, ROLE.PARTNER],
  [SectionRole.ESGEditSurvey]: [ROLE.OPERATOR, ROLE.PARTNER],

  [SectionRole.McCard]: [ROLE.PARTNER, ROLE.OPERATOR],
  [SectionRole.McTopicList]: [ROLE.ADMIN],
  [SectionRole.McEditSurvey]: [ROLE.OPERATOR, ROLE.PARTNER],
  [SectionRole.McSurveyList]: [ROLE.OPERATOR, ROLE.ADMIN, ROLE.PARTNER],
  [SectionRole.McAddYear]: [ROLE.OPERATOR, ROLE.PARTNER],
  [SectionRole.McAddSurvey]: [ROLE.OPERATOR, ROLE.PARTNER],
  [SectionRole.McAddDashboardElement]: [ROLE.OPERATOR, ROLE.PARTNER],

  [SectionRole.MaCard]: [ROLE.PARTNER, ROLE.OPERATOR],
  [SectionRole.MaStructureList]: [ROLE.ADMIN],
  [SectionRole.MaCreation]: [ROLE.OPERATOR],
  [SectionRole.MaFill]: [ROLE.STAKEHOLDER],
  [SectionRole.MaTopic]: [ROLE.ADMIN],
  [SectionRole.MaSurveyList]: [ROLE.OPERATOR, ROLE.ADMIN, ROLE.PARTNER],

  [SectionRole.NfrCard]: [ROLE.PARTNER, ROLE.OPERATOR],

  [SectionRole.EASupplierCard]: [ROLE.PARTNER, ROLE.OPERATOR],
  [SectionRole.EASupplierCardFill]: [ROLE.EA_SUPPLIER],
  [SectionRole.EASupplierSurvey]: [ROLE.EA_SUPPLIER],
  [SectionRole.EASupplierTopicList]: [ROLE.ADMIN, ROLE.PARTNER, ROLE.OPERATOR],
  [SectionRole.EASupplierList]: [ROLE.ADMIN, ROLE.PARTNER, ROLE.OPERATOR],
  [SectionRole.EASupplierDetail]: [ROLE.ADMIN, ROLE.PARTNER, ROLE.OPERATOR],
  [SectionRole.EASupplierStructureList]: [ROLE.ADMIN, ROLE.PARTNER, ROLE.OPERATOR],
  [SectionRole.EASupplierAddStructure]: [ROLE.ADMIN],

}

const adminRole = [ROLE.SYSTEM_ADMIN];
export const checkSectionRole = (sectionRole: SectionRole, currentRole: ROLE, type: 'action' | 'page' = 'page') => {

  const arr: ROLE[] = SectionRoleDict[sectionRole];
  if (arr?.length === 0 && type === 'page') return true;
  return arr?.includes(currentRole!);
}

export const checkHaveServices = (targetService: SERVICE, currentUser: IUser): boolean => {
  if (!environment.SERVICE_ENABLED.includes(targetService)) return false;
  //if ([...adminRole, ROLE.PARTNER].includes(currentUser.role!)) return true;
  if (currentUser.services && currentUser.services.length > 0) {
    return currentUser.services.includes(targetService);
  }
  return false;
}
