import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { RoutingPathConfig } from '../configs/routing-path.config';
import { AuthService } from '@shared/services/auth.service';
import { catchError, map, of } from 'rxjs';

export const authGuard: CanActivateFn = () => {
  const authService = inject(AuthService);
  const router = inject(Router);
  return authService.getLoggedUser().pipe(
    map(loggedIn => {
      return loggedIn ? true : router.createUrlTree([RoutingPathConfig.LOGIN])
    }),
    catchError(() => {
      router.navigate([RoutingPathConfig.LOGIN]);
      return of(false);
    })
  )
};
