import { Component, Input } from '@angular/core';
import { FormGroup, FormGroupDirective, ReactiveFormsModule } from '@angular/forms';
import { IFormQuestionModel } from '@shared/components/dynamic-form/models/form-model.interface';
import { CommonModule } from '@angular/common';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CheckboxModule } from 'primeng/checkbox';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TagModule } from 'primeng/tag';

@Component({
  selector: 'app-dynamic-checkboxs',
  templateUrl: './dynamic-checkboxs.component.html',
  styleUrls: ['./dynamic-checkboxs.component.css'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    RadioButtonModule,
    CheckboxModule,
    InputSwitchModule,
    TagModule,
  ],
})
export class DynamicCheckboxsComponent {
  @Input({ required: true }) field!: IFormQuestionModel;
  formName: FormGroup;

  constructor(private formgroupDirective: FormGroupDirective) {
    this.formName = formgroupDirective.control;
    this.formName.valueChanges.subscribe(res => {
      //Fixture for multiple checkbox selection
      if (res.answer) {
        this.formName.patchValue({
          answer: [...res.answer],
        }, { emitEvent: false });
      }
    });
  }
}
